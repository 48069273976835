import React from 'react';
import styled from 'styled-components';
import InstagramLogo from '@assets/images/instagram.svg';
import VimeoLogo from '@assets/images/vimeo.svg';

const Container = styled.footer`
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 40px;
  margin-top: ${({ home }) => (home ? '0' : '40px')};
  display: block;
  @media (max-width: 900px) {
    background-color: #000;
  }

  p {
    margin-bottom: 15px;
  }
  section {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    margin-top: 0px;
    color: #807f80;
    /* padding: 40px 10px; */
    /* width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  position: relative; */
    @media (max-width: 900px) {
      display: block;
      font-size: 11px;
      padding: 20px 0;
      text-align: center;
      background-color: #000;
    }

    a,
    p {
      display: block;
      /* margin-bottom: 15px; */
      text-decoration: none;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: #807f80;

      img {
        width: 23px;
        display: block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    margin-bottom: 30px;
  }
`;

const Contact = styled.section`
  display: flex;
  border-bottom: solid 1px #807f80;
  border-top: ${({ home }) => (home ? 'none' : 'solid 1px #807f80')};

  div {
    margin-right: 20px;

    @media (max-width: 900px) {
      margin-bottom: 20px;
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

const Copyright = styled.p`
  padding: 40px 0;
  font-size: 14px;
  color: #807f80;
  /* margin-top: ${({ home }) => (home ? '0' : '30px')}; */
  margin-bottom: 0 !important;

  @media (max-width: 900px) {
    padding: 20px 0;
    font-size: 11px;
    text-align: center;
  }
`;

const Title = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  color: ${({ home }) => (home ? '#fff' : '#000')};

  font-weight: 500;
  margin-bottom: 40px !important;

  @media (max-width: 900px) {
    display: block;
    font-size: 11px !important;
    margin-bottom: 10px !important;
    color: #fff;
  }
`;

export default function Footer({ home }) {
  return (
    <Container home={home}>
      <Contact home={home}>
        <div>
          <Title home={home} style={{ color: '#807f80' }}>
            Film | Television Representation
          </Title>
        </div>
        <div>
          <Title home={home}>CAA</Title>
          <p>Ida Ziniti | Maha Dakhil | Connie Yan</p>
          <p>+1 424 288 2000</p>
        </div>

        <div>
          <Title home={home}>Direct</Title>
          <p>Ben Shirinian</p>
          <p>
            <a href="mailto:ben@benshirinian.com">ben@benshirinian.com</a>
          </p>
        </div>
      </Contact>
      <Copyright
        home={home}
      >{`©${new Date().getFullYear()} Ben Shirinian`}</Copyright>
    </Container>
  );
}
