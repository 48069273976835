import React from 'react';
import styled from 'styled-components';

const Container = styled.picture`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s; /* Animation */
  }
`;

export default function Image({ alt, src, width, gif }) {
  return (
    <Container>
      {gif ? (
        <img src={`${src}`} alt={alt} />
      ) : (
        <>
          <source type="image/webp" srcSet={`${src}?w=${width}&fm=webp`} />
          <source srcSet={`${src}?w=${width}&fm=jpg`} />
          <img src={`${src}?w=${width}&fm=jpg`} alt={alt} />
        </>
      )}
    </Container>
  );
}
