import React from 'react';
import { Link } from 'gatsby';
import Navigation from '@components/navigation';
import Logo from '@assets/images/lookout-new.png';

import styled from 'styled-components';

const HeaderStyled = styled.header`
  display: flex;
  justify-content: ${({ home }) => (home ? 'space-between' : 'center')};
  justify-content: center;

  align-items: center;
  padding-top: 40px;

  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 600px) {
    margin-bottom: 30px;
  }
`;

const LogoStyled = styled.h1`
  a {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 45px;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  img {
    display: block;
    width: 270px;
    position: relative;
    top: 1px;
    /* filter: ${({ home }) => (home ? 'none' : 'invert(100%)')}; */
  }

  span {
    display: block;
    font-size: 32px;
    font-weight: 200;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export default function Header({ location }) {
  return (
    <HeaderStyled home={location?.pathname === '/'}>
      <LogoStyled home={location?.pathname === '/'}>
        <Link to="/">
          Ben Shirinian<span>Director</span>
        </Link>
      </LogoStyled>
      {/* <Navigation /> */}
    </HeaderStyled>
  );
}
