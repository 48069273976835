import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, Link, graphql } from 'gatsby';

const NavItem = styled.li`
  font-size: 18px;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;

  a {
    color: var(--colour-nav);
    text-decoration: none;
  }
`;

export default function Navigation({ test }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  const { menuLinks } = data.site.siteMetadata;

  return (
    <nav>
      <ul>
        {menuLinks.map(menuLink => {
          return (
            <NavItem key={menuLink.link}>
              <Link to={menuLink.link}>{menuLink.name}</Link>
            </NavItem>
          );
        })}
      </ul>
    </nav>
  );
}

// activeStyle={{ color: 'red' }}
